<template>
  <div
    class="plat-adv-container"
    v-lazy:background-image="{src: require('../../../assets/images/bg4.png')}"
  >
    <div class="page-outter">
      <div align="center">
        <img
          class="plat-adv-title"
          v-lazy="require('../../../assets/images/title6.png')"
        />
        <div class="plat-adv-inner">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div class="plat-adv-dd">
                <div class="plat-adv-item">
                  <img v-lazy="item.icon" />
                </div>
                <span
                  class="plat-adv-item-s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformAdvantage-pt",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon4.png"),
          name: `产品生产经营全流程<br>质量追溯信息化`,
        },
        {
          icon: require("../../../assets/images/app-icon5.png"),
          name: `开放标准接口<br>可实现与企业生产多平台数据打通`,
        },
        {
          icon: require("../../../assets/images/app-icon6.png"),
          name: `雄厚技术团队<br>实现数据的可靠性安全性`,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.plat-adv-container {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  padding-bottom: 2.4rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.plat-adv-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.plat-adv-inner {
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -3rem;
    li {
      float: left;
      margin-left: 3rem;
      margin-top: 1.8rem;
    }
  }
}
.plat-adv-dd {
  width: 5.8rem;
}
.plat-adv-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #dcdadd;
  border-radius: 2.4rem;
  img {
    width: 1.6rem;
  }
}
.plat-adv-item-s1 {
  display: block;
  font-size: 0.36rem;
  color: #dcdadd;
  line-height: 1.5;
  margin-top: 0.7rem;
}
</style>